<!-- =========================================================================================
    File Name: BaseTable.vue
    Description: Mixin for server-side data tables
========================================================================================== -->

<script>
var qs = require('qs');
var _debounce = require('lodash/debounce');

export default {
    data() {
        return {
            tableFields: {},
            data: [],
            total: 0,
            p: 1,
            filterForm: {
                fields_filter: {},
                order_filter: {},
                p: 0,
                per_page: 10,
            },
            search: ''
        }
    },
    watch: {
        search() {
            for (let key of Object.keys(this.filterForm.fields_filter)){
                this.filterForm.fields_filter[key].like = this.search;
            }
            this.filterForm.p = 0;
        },
        p() {
            this.filterForm.p = this.p - 1;
            this.getData();

            // Принудительно скрываем раскрытые строки
            this.$refs.trs.forEach(tr => {
                tr.collapseExpandedData()
            });
        }
    },
    computed: {
        getTotalPages() {
            return Math.ceil(this.total / this.filterForm.per_page)
        }
    },
    methods: {
        async getData() {
            await this.$http({
                method: 'post',
                url: '/udata/emarket/get_payments/' + this.shift_id + '/.json',
                data: qs.stringify(this.filterForm),
                withCredentials: true
            }).then((response) => {
                if (!response.data.error) {
                    this.tableFields = response.data.table_fields;
                    this.data = response.data.items ? Object.values(response.data.items) : [];
                    this.total = response.data.total;
                } else {
                    this.$vs.notify({
                        title:'Ошибка',
                        text: response.data.error,
                        color:'warning',
                        iconPack: 'feather',
                        icon:'icon-alert-circle'
                    })          
                }
            })
            .catch((error) => {
                this.$vs.notify({
                    title:'Ошибка',
                    text: error,
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'
                })
            });
        },
        handleSearch(search) {
            this.p = 1;
            this.search = search;
            this.debouncedGetData();
        },
        handleChangePage(p) {
            this.filterForm.p = p - 1;
            this.getData();
        },
        handleSort(key) {
            let curr_order = this.filterForm.order_filter[key];
            for (let i in this.filterForm.order_filter) {
                this.filterForm.order_filter[i] = undefined;
            }
            this.filterForm.order_filter[key] = (curr_order === 'asc') ? 'desc' : 'asc';
            this.getData();
        }
    },
    created() {
        this.debouncedGetData = _debounce(this.getData, 500);
    }
}
</script>

<style lang="scss">
.vs-table--search {
    flex-grow: 1;
    margin-left: 0px;
    max-width: 100%;
    justify-content: normal;
}
.con-input-search.vs-table--search {
    float: left;
}
.vs-table--search-input { width: 100%; }
@media (min-width: 768px) {
    .vs-table--search-input { 
        width: 33%; 
    }
}
</style>