<!-- =========================================================================================
    File Name: CustomerSearch.vue
    Description: Поиск пользователей
========================================================================================== -->


<template>
  <vx-card title="Посетители">
    <div class="w-100 h-10" v-if="Object.keys($store.state.idealReportFilter).length > 0">
      <vs-chip @click="resetIdealReportFilter" closable>{{idealReportFilterName}}</vs-chip>
    </div>
    <vs-table
      :data="data"
      :sst="true"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
      :max-items="filterForm.per_page"
      stripe
      search
    >
      <template slot="header">
        <div class="mr-4">
          <vs-select v-model="filterForm.with_active_visits" @change="debouncedGetData">
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in visitsFilterOptions"
            />
          </vs-select>
        </div>
      </template>

      <template slot="thead">
        <vs-th v-for="(field, key) in tableFields" :key="key" :sort-key="key">{{field}}</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" ref="trs">
          <vs-td v-for="(field, key) in tr.table_fields" :key="key" :data="field">
            <span v-if="field === true">
              <feather-icon icon="CheckCircleIcon" />
            </span>
            <span v-else-if="field === false" />
            <span v-else-if="key === 'phone'">{{$helpers.formatPhone(field) }}</span>
            <span v-else>{{field}}</span>
          </vs-td>

          <template class="expand-user" slot="expand">
            <div class="con-expand-users w-full">
              <div class="con-btns-user flex items-center justify-between">
                <div class="con-userx flex items-center justify-start">
                  <vs-avatar :badge="tr.status" size="45px" :src="tr.photo.src" v-if="tr.photo" />
                  <span>{{ clientName(tr.table_fields) }}</span>
                </div>
                <div class="flex">
                  <vs-button
                    type="border"
                    color="success"
                    class="mr-2"
                    @click="selectCustomer(tr)"
                  >
                    Выбрать
                  </vs-button>
                  <vs-button
                    type="border"
                    color="success"
                    class="mr-2"
                    @click="$router.push({name: 'customer-history', params: {id: tr.id}})"
                  >
                    История
                  </vs-button>
                </div>
              </div>
              <vs-list>
                <vs-list-item
                  icon-pack="feather"
                  icon="icon-mail"
                  :title="tr.table_fields['e-mail']"
                ></vs-list-item>
                <vs-list-item
                  icon-pack="feather"
                  icon="icon-phone"
                  :title="$helpers.formatPhone(tr.table_fields.phone)"
                ></vs-list-item>
              </vs-list>
            </div>
          </template>
        </vs-tr>
      </template>
    </vs-table>

    <vs-pagination
      :total="getTotalPages"
      v-model="p"
      class="mt-4"
      :maxItems="1"
      :sizeArray="1"
      prev-icon="arrow_back"
      next-icon="arrow_forward"
    />
  </vx-card>
</template>


<script>
var qs = require("qs");
import BaseTable from "@/views/components/BaseTable.vue";

export default {
  mixins: [BaseTable],
  data() {
    return {
      visitsFilterOptions: [
        { value: "all", text: "Все" },
        { value: "active", text: "В пространстве" },
        { value: "inactive", text: "Покинули" }
      ],
      filterForm: {
        with_active_visits: "all"
      }
    };
  },
  computed: {
    idealReportFilterName() {
      return (
        this.$store.state.idealReportFilter.ideal_report_filter.category_name +
        ": " +
        this.visitsFilterOptions.find(
          x => x.value === this.filterForm.with_active_visits
        ).text
      );
    }
  },
  methods: {
    clientName(client) {
      let name = [client.lname, client.fname, client.father_name];
      return name.join(" ");
    },
    async selectCustomer(tr) {
      this.$vs.loading();
      await this.$store.dispatch(
        "startClientSession",
        tr.barcode,
        this.$router.push("/").catch(() => {})
      );
      this.$vs.loading.close();
    },
    resetIdealReportFilter() {
      this.$store.commit("RESET_IDEAL_REPORT_FILTER");
      this.filterForm.with_active_visits = "all";
      this.getData();
    },
    async getData() {
      let filters = Object.assign(
        {},
        this.filterForm,
        this.$store.state.idealReportFilter
      );
      await this.$http({
        method: "post",
        url: "/udata/users/get_clients/.json",
        data: qs.stringify(filters),
        withCredentials: true
      })
      .then(response => {
        if (!response.data.error) {
          this.tableFields = response.data.table_fields;
          this.data = Object.values(response.data.items);
          this.total = response.data.total;
        } else {
          this.$vs.notify({
            title: "Ошибка",
            text: response.data.error,
            color: "warning",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        }
      })
      .catch(error => {
        this.$vs.notify({
          title: "Ошибка",
          text: error,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
      });
    }
  },
  created() {
    this.filterForm.with_active_visits = this.$store.state.userRole === 'barista' ? 'active' : 'all';
    if (this.$store.state.idealReportFilter.ideal_report_filter) {
      this.filterForm.with_active_visits = this.$store.state.idealReportFilter.ideal_report_filter.with_active_visits;
    }

    this.getData().then(() => {
      for (let key of Object.keys(this.tableFields)) {
        if (key != "is_visit_active" && key != "phone_verified") {
          // Исключаем поля "Визит активен" и "Телефон подтвержден" из поиска
          this.filterForm.fields_filter[key] = { like: null };
        }
        this.filterForm.order_filter[key] = undefined;
      }
    });
  }
};
</script>